/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.mat-form-field-disabled
{ 
    opacity: 10 !important;
} 

:host ::ng-deep .mat-tab-header {
    top: 0;
    z-index: 1000; 
    position: sticky;
    position: -webkit-sticky; /* macOS/iOS Safari */    
}

/* .mat-form-field-disabled
{ 
    opacity: unset !important;
} */ 
.mat-form-field.mat-form-field-appearance-fill {

    /* Disabled */
    &.mat-form-field-disabled {
        opacity: unset !important;
    }
}
.mat-form-field-flex{
    min-height: 45px !important;
}
.mat-form-field-disabled > .mat-form-field-wrapper > .mat-form-field-flex{
    border: none !important;
    min-height: unset !important;
    padding: 0px !important;
}
.mat-form-field-label-wrapper{
    top: -20px !important;
}
.class-selectq{
    padding: 10px !important;
}
.mat-form-field-disabled > .mat-form-field-wrapper > .mat-form-field-flex > 
.mat-form-field-infix > .class-selectq > .mat-select-trigger > 
.mat-select-arrow-wrapper{
    display: none;
}
.mat-select-min-line{
    color: black !important;
}
input{
    color: black !important;
}
